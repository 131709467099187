<template>
  <!------ kremlin location start ------->
  <div class="kremlinlocation-area">
      <ul class="d-flex justify-content-between">
          <li>
              <div :class="['number', stage == 1 ? 'active' : 'kremlinlocation-box']">
                  <div class="number">
                      <div class="text">1</div>
                  </div>
                  <p>{{ $t("booking.details") }}</p>
              </div>
          </li>
          <li :class="['number', stage == 2 ? 'active' : 'kremlinlocation-box']">
              <div class="kremlinlocation-box">
                  <div class="number">
                      <div class="text">2</div>
                  </div>
                  <p>{{ $t("booking.invitation") }}</p>
              </div>
          </li>
          <li>
              <div :class="['number', stage == 3 ? 'active' : 'kremlinlocation-box']">
                  <div class="number">
                      <div class="text">3</div>
                  </div>
                  <p>{{ $t("booking.approval") }}</p>
              </div>
          </li>
      </ul>
  </div>
  <!------ kremlin location stop ------->
</template>

<script>
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  props: {
    stage: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.banner_area {
  width: 100%;
  height: 120px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 34px 0;
}
.banner_area .banner_body {
  position: relative;
}
.banner_area .banner_body::before {
  content: "";
  position: absolute;
  top: 14px;
  left: 0;
  width: 85%;
  height: 3px;
  background: #fff;
  right: 0;
  margin: 0 auto;
}
.banner_area .banner_body .banner_box {
  text-align: center;
  position: relative;
  z-index: 9;
}
.banner_area .banner_body .banner_box .number {
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  line-height: 28px;
  color: #000;
  background: #fff;
  box-sizing: border-box;
  border-radius: 50%;
  font-size: 15px;
  font-weight: 600;
  margin: 0 auto 6px;
}
.banner_area .banner_body .banner_box h3 {
  color: #fff;
  margin: 0 0 0;
  padding: 0 0 0;
  font-size: 15px;
  font-weight: 400;
}
.banner_area .banner_body .banner_box .number.active {
  background: #0061ab;
  color: #fff;
}
</style>
